import { createReducer, on } from "@ngrx/store";
import * as ApplicationSettingAction from '../actions/application-setting.actions';
import { IApplicationSetting } from "../../models/application/application-setting.model";

export const applicationSettingFeatureKey = 'APPLICATION_SETTING_STATE_KEY';

export interface ApplicationSettingState {
  applicationSettings: IApplicationSetting[] | null
}

export const initialState: ApplicationSettingState = {
  applicationSettings: null
};

export const applicationSettingReducer = createReducer(
  initialState,
  on(ApplicationSettingAction.setApplicationSettings, (state, {applicationSettings}) => ({...state, applicationSettings}))
);
